import React from 'react'
import { getProjectJourneyData } from '@/services/ProjectJourneyService'
import { PhaseSlug } from '@/types/codegen-federation'
import { Locale, useLocale } from '@/utils/LocaleUtil'
import { isValidIso8601String } from '@/utils/date-utils'
import { TranslateFunction, useTranslate } from '@/utils/translate/translate-client'
import { isDefined } from '@/utils/types'
import { FlowPhase, GuildUpdate, ProjectJourneySuperPhase, ProjectV2 } from './types'

export type ProjectJourneyData = {
  superPhases: ProjectJourneySuperPhase[]
  shouldDisplay: boolean
}

export const useProjectJourneyData = (projectSlug?: string): ProjectJourneyData | null => {
  const [data, setData] = React.useState<ProjectJourneyData | null>(null)
  const { t } = useTranslate('watch')
  const { locale } = useLocale()

  React.useEffect(() => {
    ;(async () => {
      if (projectSlug) {
        const data = await getProjectJourneyData(projectSlug)
        setData(mapProjectJourneyData(t, locale, data.guildUpdates, data.projectV2))
      }
    })()
  }, [projectSlug, t, locale])

  return data
}

/**
 * The groupings of phases specific to this ui for Angel-external users.
 */
const EXTERNAL_SUPERPHASE_SLUGS = ['voting', 'funding', 'production', 'distribution']
type ExternalSuperphaseSlug = typeof EXTERNAL_SUPERPHASE_SLUGS[number]

export const SUPERPHASE_PHASE_MAPPING: { [key in ExternalSuperphaseSlug]: PhaseSlug[] } = {
  voting: ['new_submission', 'guild_approved', 'onboarding'],
  funding: ['express_interest', 'crowd_funding', 'pna_funding'],
  production: ['production', 'post_production', 'brand_development'],
  distribution: ['pre_sale', 'theatrical', 'guild_access', 'est_tvod', 'svod_contracts', 'free_access'],
}

/**
 * DESIGN NOTE - The ui was built to use ProjectJourneyData, so we are mapping
 * the project phase data to match that. But more importantly and less obvious, this
 * mapping solidifies what phases a project can have or at least will show in this client.
 * The reality is that into the future projects can and will have different phases, but
 * this won't respond to those. This will only be dynamic when the "external phase representation"
 * that was designed for this UI/UX is created inside the source phase manager data.
 */
export function mapProjectJourneyData(
  t: TranslateFunction,
  locale: Locale,
  guildUpdates: GuildUpdate[],
  projectV2?: ProjectV2,
): ProjectJourneyData {
  const mapProjectPhase = mapPhase.bind(null, t, locale, projectV2 ?? undefined, guildUpdates)
  return {
    shouldDisplay: !!(projectV2 && isContractSigned(projectV2) && isInDevelopment(projectV2)),
    superPhases: [
      {
        id: 'voting',
        title: t('projectJourneyVotingTitle', 'Voting'),
        description: t(
          'projectJourneyVotingDescription',
          'Guild members pick the stories that amplify light and that will shape our future.',
        ),
        phases: SUPERPHASE_PHASE_MAPPING.voting.map(mapProjectPhase).filter(isDefined),
      },
      {
        id: 'funding',
        title: t('projectJourneyFundingTitle', 'Funding'),
        description: t(
          'projectJourneyFundingDescription',
          'Invest in and support high-quality films and TV shows, sharing in their success.*',
        ),
        disclaimer: t(
          'projectJourneyFundingDisclaimer',
          '*Returns on your investment depend on the success of the film or series, and there is no guarantee of profit.',
        ),
        phases: SUPERPHASE_PHASE_MAPPING.funding.map(mapProjectPhase).filter(isDefined),
      },
      {
        id: 'production',
        title: t('projectJourneyProductionTitle', 'Production'),
        description: t(
          'projectJourneyProductionDescription',
          'Follow along as filmmakers make progress on their story.',
        ),
        phases: SUPERPHASE_PHASE_MAPPING.production.map(mapProjectPhase).filter(isDefined),
      },
      {
        id: 'distribution',
        title: t('projectJourneyDistributionTitle', 'Distribution'),
        description: t(
          'projectJourneyDistributionDescription',
          'Angel Studios takes your favorite project to the world.',
        ),
        phases: SUPERPHASE_PHASE_MAPPING.distribution.map(mapProjectPhase).filter(isDefined),
      },
    ],
  }

  function findFlowPhase(phaseSlug: PhaseSlug, project: ProjectV2): FlowPhase | undefined {
    return project.primaryFlow?.flowPhases?.find((flowPhase) => flowPhase?.phaseSlugEnum === phaseSlug) ?? undefined
  }

  function mapPhase(
    t: TranslateFunction,
    locale: Locale,
    projectV2: ProjectV2 | undefined,
    guildUpdates: GuildUpdate[],
    phaseSlug: PhaseSlug,
  ) {
    if (!projectV2) return undefined

    const flowPhase = findFlowPhase(phaseSlug, projectV2)
    if (!flowPhase) return undefined

    return {
      id: flowPhase.phaseSlugEnum,
      title: formatPhaseTitle(t, flowPhase),
      description: formatPhaseDescription(t, locale, flowPhase),
      status: flowPhase.status,
      cta: mapCta(t, projectV2, guildUpdates, flowPhase),
    }
  }

  function formatPhaseTitle(t: TranslateFunction, flowPhase: FlowPhase): string {
    switch (flowPhase.phaseSlugEnum) {
      case 'new_submission':
        return t('projectJourneyPhaseTitleNewSubmission', 'Submitted To Angel Guild')
      case 'guild_approved':
        return t('projectJourneyPhaseTitleGuildApproved', 'Guild Approved')
      case 'onboarding':
        return t('projectJourneyPhaseTitleOnboarding', 'Angel Onboarding')
      case 'express_interest':
        return t('projectJourneyPhaseTitleExpressInterest', 'Express Interest')
      case 'crowd_funding':
        return t('projectJourneyPhaseTitleCrowdFunding', 'Crowdfund investment')
      case 'pna_funding':
        return t('projectJourneyPhaseTitlePnaFunding', 'Crowdfund investment')
      case 'production':
        return t('projectJourneyPhaseTitleProduction', 'Production')
      case 'post_production':
        return t('projectJourneyPhaseTitlePostProduction', 'Post Production')
      case 'brand_development':
        return t('projectJourneyPhaseTitleBrandDevelopment', 'Brand Development')
      case 'pre_sale':
        return t('projectJourneyPhaseTitlePreSale', 'Ticket Pre-Sales')
      case 'theatrical':
        return t('projectJourneyPhaseTitleTheatrical', 'In Theaters')
      case 'guild_access':
        return t('projectJourneyPhaseTitleGuildAccess', 'Guild Early Access')
      case 'est_tvod':
        return t('projectJourneyPhaseTitleEstTvod', 'Available on DVD')
      case 'svod_contracts':
        return t('projectJourneyPhaseTitleSvodContracts', '3rd-party Streaming')
      case 'free_access':
        return t('projectJourneyPhaseTitleFreeAccess', 'Streaming for Free')
      default:
        return flowPhase.phaseName
    }
  }

  function formatPhaseDescription(t: TranslateFunction, locale: Locale, flowPhase: FlowPhase): string | undefined {
    switch (flowPhase.phaseSlugEnum) {
      // TODO: these are inaccurate for historical projects. Probably need phase manager to fix its data before exposing
      // See: https://angelstudiosteam.slack.com/archives/C06NFHU419R/p1724105447424699
      // case 'new_submission':
      // case 'guild_approved':
      //   return flowPhase.updatedAt && flowPhase.status === 'completed'
      //     ? t('projectJourneyPhaseDescriptionSubmittedOn', 'Submitted on {{date}}', {
      //         date: formatDate(locale, flowPhase.updatedAt),
      //       })
      //     : undefined
      case 'onboarding':
        return t('projectJourneyPhaseDescriptionOnboarding', 'Strategy, synopsis, title, basic branding')
      case 'crowd_funding':
        return t('projectJourneyPhaseDescriptionCrowdFunding', 'Regulated equity-based crowdfunding')
      case 'pna_funding':
        return t('projectJourneyPhaseDescriptionPnaFunding', 'Prints and Advertising crowdfunding')
      case 'production':
        return t('projectJourneyPhaseDescriptionProduction', 'Talent sourcing, set building, filming, editing')
      case 'post_production':
        return t('projectJourneyPhaseDescriptionPostProduction', 'Official trailer, branding, dubbing, subtitles')
      case 'brand_development':
        return t('projectJourneyPhaseDescriptionBrandDevelopment', 'Key art, trailers, press kit, ad building')
      case 'pre_sale':
        return t('projectJourneyPhaseDescriptionPreSale', 'Early tickets drive theater reach')
      // TODO: someday make region-aware
      case 'theatrical': {
        const releaseWindowStart = flowPhase.releaseWindows?.at(-1)?.start
        return releaseWindowStart
          ? t('projectJourneyPhaseDescriptionTheatrical', 'Coming to theaters {{date}}', {
              date: formatDate(locale, releaseWindowStart),
            })
          : undefined
      }
      case 'guild_access':
        return t('projectJourneyPhaseDescriptionGuildAccess', 'Streaming to Guild members first')
      case 'est_tvod':
        return t('projectJourneyPhaseDescriptionEstTvod', 'Purchasable in stores or online')
      case 'svod_contracts':
        return t('projectJourneyPhaseDescriptionSvodContracts', 'Streaming on other services like Amazon')
      case 'free_access':
        return t('projectJourneyPhaseDescriptionFreeAccess', 'Coming to Angel Pay-It-Forward')
    }
  }

  function mapCta(t: TranslateFunction, project: ProjectV2, guildUpdates: GuildUpdate[], flowPhase: FlowPhase) {
    switch (flowPhase.phaseSlugEnum) {
      case 'new_submission': {
        const guildApprovedFlowPhase = findFlowPhase('guild_approved', project)
        return {
          text: t('projectJourneyPhaseCtaViewVotingResults', 'Download the app to see results'),
          href: 'https://www.angel.com/guild/voting',
          isGuildMemberOnly: true,
          disabled: !!guildApprovedFlowPhase && guildApprovedFlowPhase.status !== 'completed',
          requiresFundingInterstitial: false,
        }
      }
      case 'crowd_funding':
        return {
          text: t('projectJourneyPhaseCtaViewFundingResults', 'View funding results'),
          href: 'https://invest.angel.com/' + project.slug,
          disabled: !(flowPhase.status === 'current' || flowPhase.status === 'completed'),
          isGuildMemberOnly: false,
          requiresFundingInterstitial: true,
        }
      case 'pna_funding':
        return {
          text: t('projectJourneyPhaseCtaInvestPna', 'Invest in P&A offering'),
          href: 'https://invest.angel.com/' + project.slug,
          disabled: !(flowPhase.status === 'current' || flowPhase.status === 'completed'),
          isGuildMemberOnly: false,
          requiresFundingInterstitial: true,
        }
      case 'production': {
        const href = formatProductionUpdatesUrl(guildUpdates)
        return href
          ? {
              text: t('projectJourneyPhaseCtaProductionUpdates', 'Production Updates'),
              href,
              disabled: !['completed', 'current'].includes(flowPhase.status),
              isGuildMemberOnly: true,
              requiresFundingInterstitial: false,
            }
          : undefined
      }
      case 'pre_sale':
        return {
          text: t('projectJourneyPhaseCtaClaimGuildTickets', 'Claim Guild Tickets'),
          href: 'https://www.angel.com/tickets/' + project.slug,
          disabled: !['current', 'completed'].includes(flowPhase.status),
          isGuildMemberOnly: true,
          requiresFundingInterstitial: false,
        }
    }
  }

  function formatProductionUpdatesUrl(guildUpdates: GuildUpdate[]): string | undefined {
    const latestUpdate = guildUpdates.at(-1)
    return latestUpdate ? `/guild/updates/${latestUpdate.id!}` : undefined
  }

  function formatDate(locale: Locale, dateStr: string): string {
    return isValidIso8601String(dateStr)
      ? new Date(dateStr).toLocaleDateString(locale, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      : ''
  }

  function isContractSigned(projectV2: ProjectV2) {
    return !!projectV2.primaryFlow?.flowPhases?.find(
      (flowPhase) => flowPhase?.status === 'completed' && flowPhase?.phaseSlugEnum === 'contract_signed',
    )
  }

  function isInDevelopment(projectV2: ProjectV2): boolean {
    return !!projectV2.primaryFlow?.flowPhases?.find(
      (flowPhase) => flowPhase?.status === 'current' && flowPhase?.departmentSlugEnum === 'development',
    )
  }
}
