import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const PictureFrameIcon: React.FC<OneColorIconProps> = ({ className, color = 'white', size = 16 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2.16667 15.5C1.70833 15.5 1.31597 15.3368 0.989583 15.0104C0.663194 14.684 0.5 14.2917 0.5 13.8333V2.16667C0.5 1.70833 0.663194 1.31597 0.989583 0.989583C1.31597 0.663194 1.70833 0.5 2.16667 0.5H13.8333C14.2917 0.5 14.684 0.663194 15.0104 0.989583C15.3368 1.31597 15.5 1.70833 15.5 2.16667V13.8333C15.5 14.2917 15.3368 14.684 15.0104 15.0104C14.684 15.3368 14.2917 15.5 13.8333 15.5H2.16667ZM2.16667 13.8333H13.8333V2.16667H2.16667V13.8333ZM3.83333 12.1667H12.1667C12.3333 12.1667 12.4583 12.0903 12.5417 11.9375C12.625 11.7847 12.6111 11.6389 12.5 11.5L10.2083 8.4375C10.125 8.32639 10.0139 8.27083 9.875 8.27083C9.73611 8.27083 9.625 8.32639 9.54167 8.4375L7.375 11.3333L5.83333 9.27083C5.75 9.15972 5.63889 9.10417 5.5 9.10417C5.36111 9.10417 5.25 9.15972 5.16667 9.27083L3.5 11.5C3.38889 11.6389 3.375 11.7847 3.45833 11.9375C3.54167 12.0903 3.66667 12.1667 3.83333 12.1667Z"
        fill={getHexColor(color)}
      />
    </svg>
  )
}
