import React, { FC } from 'react'
import { Button } from '@/atoms/Button'
import { ArrowRightIcon } from '@/atoms/Icons/ArrowRightIcon'
import { EyebrowMD, TitleSM } from '@/atoms/Text'
import { OneColorIconProps } from '@/atoms/utils'
import { CircleGraph } from './CircleGraph'

interface Props {
  title: string
  label: string
  icon: FC<OneColorIconProps>
  progress: number
  onClick: () => void
}

export const ProjectJourneySuperPhaseCard: FC<Props> = ({ title, label, icon: Icon, progress, onClick }) => {
  return (
    <Button
      variant="plain"
      onClick={onClick}
      className="flex items-center gap-3 rounded-md bg-gray-900 p-3 active:opacity-75"
    >
      <div className="relative flex shrink-0">
        <CircleGraph value={progress} radius={22} />
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <Icon size={20} color="white" />
        </div>
      </div>

      <div className="text-left">
        <TitleSM>{title}</TitleSM>
        <EyebrowMD color="gray-600" className="cursor-pointer">
          {label}
        </EyebrowMD>
      </div>

      <ArrowRightIcon size={24} color="white" className="ml-auto" />
    </Button>
  )
}
