import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const MovieCreditsIcon: React.FC<OneColorIconProps> = ({ size = 64, color = 'white', className }) => {
  const hex = getHexColor(color)
  return (
    <svg
      className={className}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M10.6668 56C9.20016 56 7.94461 55.4778 6.90016 54.4333C5.85572 53.3889 5.3335 52.1333 5.3335 50.6667V13.3333C5.3335 11.8667 5.85572 10.6111 6.90016 9.56667C7.94461 8.52222 9.20016 8 10.6668 8H53.3335C54.8002 8 56.0557 8.52222 57.1002 9.56667C58.1446 10.6111 58.6668 11.8667 58.6668 13.3333V50.6667C58.6668 52.1333 58.1446 53.3889 57.1002 54.4333C56.0557 55.4778 54.8002 56 53.3335 56H10.6668ZM10.6668 50.6667H16.0002V45.3333H10.6668V50.6667ZM48.0002 50.6667H53.3335V45.3333H48.0002V50.6667ZM29.3335 45.3333H34.6668V29.3333H29.3335V45.3333ZM10.6668 40H16.0002V34.6667H10.6668V40ZM48.0002 40H53.3335V34.6667H48.0002V40ZM10.6668 29.3333H16.0002V24H10.6668V29.3333ZM48.0002 29.3333H53.3335V24H48.0002V29.3333ZM32.0002 24C32.7557 24 33.3891 23.7444 33.9002 23.2333C34.4113 22.7222 34.6668 22.0889 34.6668 21.3333C34.6668 20.5778 34.4113 19.9444 33.9002 19.4333C33.3891 18.9222 32.7557 18.6667 32.0002 18.6667C31.2446 18.6667 30.6113 18.9222 30.1002 19.4333C29.5891 19.9444 29.3335 20.5778 29.3335 21.3333C29.3335 22.0889 29.5891 22.7222 30.1002 23.2333C30.6113 23.7444 31.2446 24 32.0002 24ZM10.6668 18.6667H16.0002V13.3333H10.6668V18.6667ZM48.0002 18.6667H53.3335V13.3333H48.0002V18.6667ZM21.3335 50.6667H42.6668V13.3333H21.3335V50.6667Z"
        fill={hex}
      />
    </svg>
  )
}
