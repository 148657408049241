import { createContext, PropsWithChildren, ReactElement, useContext, useMemo } from 'react'
import { CatalogTitle } from '@/services/ContentCatalog'
import { ProjectMeta } from '@/services/ProjectsService'

export type ProjectMetaContext = {
  catalogTitle?: CatalogTitle
  project?: ProjectMeta
}

export interface ProjectMetaProviderProps extends PropsWithChildren<unknown> {
  catalogTitle?: CatalogTitle
  project?: ProjectMeta
}

export type ProjectMetaProvider = (props: ProjectMetaProviderProps) => ReactElement<ProjectMetaContext>

export const ProjectMetaContext = createContext<ProjectMetaContext>({})

export const ProjectMetaProvider = ({
  catalogTitle,
  children,
  project,
}: ProjectMetaProviderProps): ReactElement<ProjectMetaContext> => {
  const value = useMemo(() => {
    return { project, catalogTitle }
  }, [project, catalogTitle])
  return <ProjectMetaContext.Provider value={value}>{children}</ProjectMetaContext.Provider>
}

export const useProjectMeta = (): ProjectMeta => {
  const projectContext = useContext(ProjectMetaContext)

  if (!projectContext.project) {
    return {} as ProjectMeta
  }

  return projectContext.project
}
