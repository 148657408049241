import React, { FC, Fragment, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Button } from '@/atoms/Button'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { CaptionSM, HeadingXS, ParagraphMD } from '@/atoms/Text'
import useWindowSize from '@/utils/useWindowSize'
import { ProjectJourneySubPhaseItem } from './ProjectJourneySubPhaseItem'
import { ProjectJourneySuperPhase } from './types'
import { getSubPhaseIcon } from './utils'

interface Props extends Partial<ProjectJourneySuperPhase> {
  isOpen: boolean
  onClose: () => void
  userIsGuildMember: boolean
}

const fadeIn = {
  enter: 'ease-out duration-300',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'ease-in duration-200',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
}

const slideUp = {
  enter: 'ease-out duration-300',
  enterFrom: 'opacity-0 translate-y-full',
  enterTo: 'opacity-100 translate-y-0',
  leave: 'ease-in duration-200',
  leaveFrom: 'opacity-100 translate-y-0',
  leaveTo: 'translate-y-full',
}

const transitionMap = { fadeIn, slideUp }

export const ProjectJourneyModal: FC<Props> = ({
  title,
  description,
  phases,
  userIsGuildMember,
  disclaimer,
  isOpen,
  onClose,
}) => {
  const { innerWidth } = useWindowSize()
  const transition = useMemo(() => (innerWidth <= 640 ? 'slideUp' : 'fadeIn'), [innerWidth])

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={classNames({
          'fixed inset-0 flex transform flex-col font-whitney z-[999] pt-6 md:pb-6': true,
          'items-center justify-center': transition !== 'slideUp',
          'justify-end': transition === 'slideUp',
        })}
        onClose={onClose}
      >
        <Transition.Child {...fadeIn} as="div">
          <Dialog.Overlay className="absolute inset-0 bg-black/75 transition-all" />
        </Transition.Child>

        <Transition.Child as="div" {...transitionMap[transition]} className="relative max-h-full max-w-full">
          <div className="max-h-full w-[524px] max-w-full overflow-y-auto rounded-t-2xl bg-white md:rounded-2xl">
            <div className={classNames('p-6 pt-10 md:p-8')}>
              <Button
                variant="plain"
                className="absolute right-6 top-4 z-50 flex h-8 w-8 items-center justify-center rounded-full bg-core-gray-200 active:opacity-75 sm:top-5"
                onClick={onClose}
                aria-label="Close Dialog"
              >
                <CloseIcon className="h-4 w-4" />
              </Button>

              <HeadingXS className="mb-3" weight="bold">
                {title}
              </HeadingXS>
              <ParagraphMD color="gray-700" className="mb-6">
                {description}
              </ParagraphMD>

              {phases?.map((phase, idx) => (
                <ProjectJourneySubPhaseItem
                  key={phase.id}
                  {...phase}
                  icon={getSubPhaseIcon(phase.id)}
                  nextIsLit={['completed', 'current'].includes(phases[idx + 1]?.status)}
                  isLast={idx === phases.length - 1}
                  userIsGuildMember={userIsGuildMember}
                />
              ))}

              {disclaimer && (
                <CaptionSM color="gray-600" className="mt-6">
                  {disclaimer}
                </CaptionSM>
              )}
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}
