import { ComponentProps } from 'react'
import { CrowdfundIcon } from '@/atoms/Icons/CrowdfundIcon'
import { GuildIconMono } from '@/atoms/Icons/GuildIconMono'
import { MovieCreditsIcon } from '@/atoms/Icons/MovieCreditsIcon'
import { MovieSpecialIcon } from '@/atoms/Icons/MovieSpecialIcon'
import { PayItForwardOutlineIcon } from '@/atoms/Icons/PayItForwardOutlineIcon'
import { PictureFrameIcon } from '@/atoms/Icons/PictureFrameIcon'
import { PlaySquareIcon } from '@/atoms/Icons/PlaySquareIcon'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'

const superPhaseIconMap = {
  voting: GuildIconMono,
  funding: CrowdfundIcon,
  production: MovieSpecialIcon,
  distribution: TicketIcon,
}

export const getSuperPhaseIcon = (id: string) => superPhaseIconMap[id as keyof typeof superPhaseIconMap]

const subPhaseIconMap = {
  'equity-crowdfund': CrowdfundIcon,
  'p-a-crowdfund': CrowdfundIcon,
  'brand-development': (props: ComponentProps<typeof PictureFrameIcon>) => <PictureFrameIcon {...props} size={16} />,
  'pre-sales': TicketIcon,
  'in-theaters': MovieCreditsIcon,
  'early-access': GuildIconMono,
  dvd: PlaySquareIcon,
  'external-streaming': PlaySquareIcon,
  'internal-streaming': PayItForwardOutlineIcon,
}

export const getSubPhaseIcon = (id: string) => subPhaseIconMap[id as keyof typeof subPhaseIconMap]
