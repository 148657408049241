import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const TwitterXIcon: React.FC<OneColorIconProps> = ({ size = 25, color = 'white', className }) => {
  return (
    <svg
      data-testid="x-icon"
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={getHexColor(color)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.258 10.152L23.176 0h-2.113l-7.747 8.813L7.133 0H0l9.352 13.328L0 23.973h2.113l8.176-9.309 6.531 9.309h7.133zm-2.895 3.293l-.949-1.328L2.875 1.56h3.246l6.086 8.523.945 1.328 7.91 11.078h-3.246zm0 0"></path>
    </svg>
  )
}
