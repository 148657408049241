import { useMemo } from 'react'
import {
  ApolloClient,
  ApolloError,
  ApolloQueryResult,
  gql,
  NormalizedCacheObject,
  OperationVariables,
  useQuery,
} from '@apollo/client'
import { formatPrice, getPriceForCurrency } from '@/services/PriceFormatter'
import { GetProjectFundingGoalQuery } from '@/types/codegen-federation'
import { getIsTheatrical } from '@/utils/SpecialProjectUtils'

const PROJECT_FUNDING_GOAL_QUERY = gql`
  query getProjectFundingGoal($slug: String!) {
    activeGoalForProject(projectSlug: $slug) {
      amount
      backers
      groupName
      id
      name
      percentRaised
      projectName
      projectSlug
      isActive
      raised
    }
  }
`

const PROJECT_VIEWS_QUERY = gql`
  query getProjectMetrics($projectSlug: String!) {
    project(slug: $projectSlug) {
      id
      name
    }
    projectMetrics(projectSlug: $projectSlug) {
      viewsAtLeastThirtySecondsLong
    }
  }
`

interface UseGoalProps {
  slug?: string
  client?: ApolloClient<NormalizedCacheObject>
}

export interface UseGoalValues {
  loading: boolean
  error?: ApolloError
  raisedRaw?: number | null
  raised?: string | null
  cost?: string | null
  costRaw?: number | null
  percentProgress?: number | null
  isActive?: boolean | null
  backers?: string | null
  groupName?: string | null
  name?: string | null
  refetch?: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<GetProjectFundingGoalQuery>> | null
}

export const useGoal = ({ slug, client }: UseGoalProps): UseGoalValues => {
  const { data, loading, error, refetch } = useQuery<GetProjectFundingGoalQuery>(PROJECT_FUNDING_GOAL_QUERY, {
    variables: {
      slug,
    },
    skip: !slug,
    client,
    errorPolicy: 'all',
  })

  const currency = 'USD'
  const locale = 'en-US'
  const {
    amount = 0,
    backers: unsafeBackers,
    percentRaised: unsafePercentRaised,
    raised: unsafeRaised,
    groupName,
    name,
    isActive,
  } = data?.activeGoalForProject || {}

  const raised = unsafeRaised ?? 0
  const percentRaised = unsafePercentRaised ?? 0
  const backers = unsafeBackers ?? 0

  const showTicketsNotDollars = slug && getIsTheatrical(slug)
  const ticketPrice = 14

  const formattedRaised = showTicketsNotDollars
    ? new Intl.NumberFormat(locale).format(getPriceForCurrency(raised, currency) / ticketPrice)
    : formatPrice(raised, { currency, includeDecimals: false, locale })
  const formattedCost = showTicketsNotDollars
    ? new Intl.NumberFormat(locale).format(getPriceForCurrency(amount, currency) / ticketPrice)
    : formatPrice(amount, { currency, includeDecimals: false, locale })
  const formattedPercent = percentRaised * 100
  const formattedBackers = new Intl.NumberFormat(locale).format(backers)

  return useMemo(
    () =>
      !slug
        ? {
            loading: false,
          }
        : {
            loading,
            error,
            isActive,
            raisedRaw: unsafeRaised,
            raised: formattedRaised,
            cost: formattedCost,
            costRaw: amount,
            percentProgress: formattedPercent,
            backers: formattedBackers,
            groupName,
            name,
            refetch,
          },
    [
      slug,
      loading,
      error,
      isActive,
      unsafeRaised,
      formattedRaised,
      formattedCost,
      amount,
      formattedPercent,
      formattedBackers,
      groupName,
      name,
      refetch,
    ],
  )
}

export const useViewsGoal = ({
  projectSlug,
  client,
}: {
  projectSlug: string
  client?: ApolloClient<NormalizedCacheObject>
}) => {
  const { data, loading, error } = useQuery(PROJECT_VIEWS_QUERY, {
    variables: {
      projectSlug,
    },
    skip: !projectSlug,
    client,
    errorPolicy: 'all',
  })

  const views =
    data?.projectMetrics
      ?.map((metric: { viewsAtLeastThirtySecondsLong: number }) => metric.viewsAtLeastThirtySecondsLong)
      .reduce((acc: number, curr: number) => acc + curr, 0) ?? 0

  return { views, loading, error }
}
