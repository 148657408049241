import { gql } from '@apollo/client'

export const GET_PROJECT_V2 = gql`
  query fetchProjectV2ForProjectJourney($projectSlug: String!) {
    projectV2BySlug(slug: $projectSlug) {
      id
      slug
      name
      primaryFlow {
        id
        name
        insertedAt
        flowPhases {
          id
          status
          updatedAt
          phaseName
          phaseSlugEnum
          departmentSlugEnum
          releaseWindows {
            start
          }
        }
      }
    }
  }
`

export const GET_PROJECT = gql`
  query fetchProjectForProjectJourney($projectUuid: String!) {
    guildUpdates(input: { projectId: $projectUuid }) {
      id
    }
  }
`
