import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const PlaySquareIcon: React.FC<OneColorIconProps> = ({ size = 24, color = 'white', className }) => {
  const hex = getHexColor(color)

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.05 15.5L15.2 12.85C15.5167 12.6667 15.675 12.3833 15.675 12C15.675 11.6167 15.5167 11.3333 15.2 11.15L11.05 8.5C10.7167 8.28333 10.375 8.26667 10.025 8.45C9.675 8.63333 9.5 8.925 9.5 9.325V14.675C9.5 15.075 9.675 15.3667 10.025 15.55C10.375 15.7333 10.7167 15.7167 11.05 15.5ZM4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.97933 4.196 3.45 4 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.8043 4.97933 22 5.45 22 6V18C22 18.55 21.8043 19.021 21.413 19.413C21.021 19.8043 20.55 20 20 20H4ZM4 18H20V6H4V18Z"
        fill={hex}
      />
    </svg>
  )
}
