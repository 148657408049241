import React, { FC } from 'react'
import classNames from 'classnames'
import { Button, LinkButton } from '@/atoms/Button'
import { ArrowRightIcon } from '@/atoms/Icons/ArrowRightIcon'
import { CheckIcon } from '@/atoms/Icons/CheckIcon'
import { CaptionMD, LabelSM, TitleMD } from '@/atoms/Text'
import { OneColorIconProps } from '@/atoms/utils'
import { BeforeYouLeaveProvider } from '@/contexts/BeforeYouLeaveContext'
import { BeforeYouLeaveModal } from '@/molecules/BeforeYouLeaveModal'
import { AngelFundingLink } from '@/molecules/InvestLink'
import { useTranslate } from '@/utils/translate/translate-client'
import { ProjectJourneySubPhase } from './types'

interface Props extends ProjectJourneySubPhase {
  icon?: FC<OneColorIconProps>
  nextIsLit: boolean
  isLast: boolean
  userIsGuildMember: boolean
}

export const ProjectJourneySubPhaseItem: FC<Props> = ({
  title,
  description,
  cta,
  icon: Icon,
  status,
  nextIsLit,
  isLast,
  userIsGuildMember,
}) => {
  const isComplete = status === 'completed'
  const isCurrent = status === 'current'
  const { t } = useTranslate('guild')
  const resolvedIsDisabled = cta?.disabled || (cta?.isGuildMemberOnly && !userIsGuildMember)

  return (
    <div
      className={classNames('relative flex items-start border-l-[6px] ml-3', {
        'pb-8 border-core-gray-300': !isLast,
        'border-transparent': isLast,
      })}
    >
      {!isLast && (
        <div
          className={classNames('absolute top-0 -left-[6px] w-[6px] bg-core-oxide', {
            'h-3/5 rounded-b-md': isComplete && !nextIsLit,
            'h-full -mb-1': isComplete && nextIsLit,
          })}
        />
      )}

      {isCurrent ? (
        <div className="relative flex h-8 w-8 translate-x-[-19px] items-center justify-center rounded-full border-2 border-gray-300 bg-white">
          <div
            className={classNames(
              'absolute top-[-2px] left-[-2px] w-[calc(100%+4px)] h-4 bg-core-oxide rounded-tl-2xl rounded-tr-2xl',
              {
                'bg-core-oxide': isComplete || isCurrent,
                'bg-white border-2 border-gray-300': !isComplete && !isCurrent,
              },
            )}
          ></div>
        </div>
      ) : (
        <div
          className={classNames(
            'w-8 h-8 bg-core-oxide rounded-full flex items-center justify-center translate-x-[-19px]',
            {
              'bg-core-oxide': isComplete || isCurrent,
              'bg-white border-2 border-gray-300': !isComplete && !isCurrent,
            },
          )}
        >
          {isComplete && <CheckIcon color="white" size={20} />}
          {!isComplete && Icon && <Icon color="gray-600" size={20} />}
        </div>
      )}

      <div>
        <TitleMD className="mb-1">{title}</TitleMD>
        {description && (
          <CaptionMD weight="normal" color="gray-600">
            {description}
          </CaptionMD>
        )}

        {cta && (
          <div className="mt-3">
            {cta.requiresFundingInterstitial && !cta.disabled ? (
              <BeforeYouLeaveProvider>
                <BeforeYouLeaveModal />
                <AngelFundingLink linkContext="Project Journey" href={cta.href}>
                  <Button variant="black">
                    <div className="flex items-center gap-2">
                      <LabelSM weight="semibold">{cta.text}</LabelSM>
                      <ArrowRightIcon color={resolvedIsDisabled ? 'gray-800' : 'white'} size={20} />
                    </div>
                  </Button>
                </AngelFundingLink>
              </BeforeYouLeaveProvider>
            ) : (
              <LinkButton variant="black" href={cta.href} external disabled={resolvedIsDisabled}>
                <div className="flex items-center gap-2">
                  <LabelSM weight="semibold">{cta.text}</LabelSM>
                  <ArrowRightIcon color={resolvedIsDisabled ? 'gray-800' : 'white'} size={20} />
                </div>
              </LinkButton>
            )}

            {cta.isGuildMemberOnly && !userIsGuildMember && (
              <CaptionMD weight="normal" color="gray-500" italic className="mt-1">
                {t('guildMembersOnly', 'Guild members only')}
              </CaptionMD>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
