import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const ArrowRightIcon: React.FC<OneColorIconProps> = ({ size = 24, color = 'white', className, onClick }) => {
  const hex = getHexColor(color)
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M11.3 19.3C11.1167 19.1167 11.0207 18.8833 11.012 18.6C11.004 18.3167 11.0917 18.0833 11.275 17.9L16.175 13H5C4.71667 13 4.479 12.904 4.287 12.712C4.09567 12.5207 4 12.2833 4 12C4 11.7167 4.09567 11.479 4.287 11.287C4.479 11.0957 4.71667 11 5 11H16.175L11.275 6.09999C11.0917 5.91665 11.004 5.68332 11.012 5.39999C11.0207 5.11665 11.1167 4.88332 11.3 4.69999C11.4833 4.51665 11.7167 4.42499 12 4.42499C12.2833 4.42499 12.5167 4.51665 12.7 4.69999L19.3 11.3C19.4 11.3833 19.471 11.4873 19.513 11.612C19.5543 11.7373 19.575 11.8667 19.575 12C19.575 12.1333 19.5543 12.2583 19.513 12.375C19.471 12.4917 19.4 12.6 19.3 12.7L12.7 19.3C12.5167 19.4833 12.2833 19.575 12 19.575C11.7167 19.575 11.4833 19.4833 11.3 19.3Z"
        fill={hex}
      />
    </svg>
  )
}
