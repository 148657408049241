import React, { useCallback, useMemo } from 'react'

interface Props {
  value: number
  radius: number
  strokeWidth?: number
  className?: string
}

export const CircleGraph: React.FC<Props> = ({ value, radius, strokeWidth = 4, className }) => {
  const { cx, cy, width, height } = useMemo(() => {
    return {
      cx: radius + strokeWidth,
      cy: radius + strokeWidth,
      width: (radius + strokeWidth) * 2,
      height: (radius + strokeWidth) * 2,
    }
  }, [radius, strokeWidth])

  const getStrokeDashOffset = useCallback(
    (value: number) => {
      const C = 2 * Math.PI * radius
      const val = C - C * value
      return val === C ? C : val + 2
    },
    [radius],
  )

  const getStrokeDashArray = useCallback(
    (value: number) => {
      const C = 2 * Math.PI * radius
      return value < 1 ? C : 0
    },
    [radius],
  )

  return (
    <div className={className}>
      <svg className="block rotate-[-91deg]" style={{ width, height }}>
        <circle className="stroke-core-gray-800" strokeWidth={strokeWidth} fill="none" cx={cx} cy={cy} r={radius} />
        <circle
          className="stroke-core-oxide"
          strokeDashoffset={getStrokeDashOffset(value)}
          strokeDasharray={getStrokeDashArray(value)}
          strokeWidth={strokeWidth}
          fill="none"
          cx={cx}
          cy={cy}
          r={radius}
        />
      </svg>
    </div>
  )
}
